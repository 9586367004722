import { type FunctionComponent } from 'react';
import { type CarouselItem } from '../../../helpers/carousel/carousel.helper';
import { type CloudinaryOptions } from '../../../helpers/cloudinary-helper/cloudinary-helper';
import { CloudinaryImage } from '../../images/cloudinary-image/cloudinary-image.component';

export type DefaultCarouselCardProps<T = CarouselItem> = {
	item: T;
	cloudinaryOptions: CloudinaryOptions;
	ariaLabel: string;
	bypassLazyLoad?: boolean;
	isModal?: boolean;
};

export const DefaultCarouselCard: FunctionComponent<DefaultCarouselCardProps> = ({
	item,
	ariaLabel,
	cloudinaryOptions,
	bypassLazyLoad = false
}) => {
	const { id, description } = item;
	return (
		<div aria-label={ariaLabel}>
			<CloudinaryImage publicID={id} description={description} options={cloudinaryOptions} bypassLazyLoad={bypassLazyLoad} />
		</div>
	);
};
